.user-permissions {
  padding: 8px;
  border: 1px solid #f3f5f7;
  border-radius: 1.2rem;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 4%);
}

.permission-table td:first-child {
  padding-left: 16px;
  min-width: 100px;
  color: #71808e;
  font-weight: 400;
  font-size: 12px;
  word-break: break-word;
  vertical-align: middle;
}

.permission-table td:last-child {
  padding-left: 16px;
  font-weight: 500;
  font-size: 13px;
  color: #132233;
  word-break: break-word;
  width: 100%;
  height: 32px;
}
